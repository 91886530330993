<template>
  <div class="index">
    <!--  头部-->
    <IndexHeader>
      <div class="tab" style="cursor: pointer">

        <div class="select" @click="close">首页</div>

        <div class="stick" />

        <div @click="toLogin">我要学习</div>

        <div class="stick" />

        <div @click="toLogin">课程作业</div>

        <div class="stick" />

        <div @click="toLogin">难题解答</div>

        <div class="stick" />

        <div class="tabLi" @click="toLogin">基本功测评</div>

      </div>
      <!--      铃铛-->
      <div v-show="!this.$store.state.user.token" class="Bell">
        <i class="el-icon-message-solid" />
      </div>
      <!--             边框-->
      <div v-show="!this.$store.state.user.token" class="stick" style="margin-left: 20px;" />
      <!--      登录-->
      <div v-if="!this.$store.state.user.token" class="HeaderLogin">
        <div class="Login" @click="toLogin"><a href="#">登录</a></div>
      </div>
      <div class="HeaderLogin">
        <div class="Login" @click="applyFor"><a href="#">{{ isNeedExamine?'试学':'注册' }}</a></div>
      </div>
    </IndexHeader>
    <div class="Box">
      <!--    ×-->
      <div class="Cha" @click="close()"><i class="el-icon-arrow-left" /><span>返回</span></div>
      <!--    标题-->
      <div class="Title">{{ detailContent.title }}</div>
      <!--    时间-->
      <div class="Time">
        <div v-if="type==0">
          <!--          发布时间：{{ detailContent.createTime }}-->
          发布时间：{{ detailContent.releaseTime }}
        </div>
        <div v-if="type==1">
          发布时间：{{ detailContent.createTime }}
        </div>
        <div>
          发布人：{{ detailContent.createBy }}
        </div>
      </div>
      <!--    内容-->
      <div style="width: 1200px;padding: 0 30px;margin-bottom: 30px">
        <div v-if="type==0" class="htmlContent ql-editor" v-html="detailContent.content" />
      </div>

      <div v-if="type==1" style="margin: 30px;padding-bottom: 30px;"> <img style="width: 1040px" :src="$downloadUrl+detailContent.detailUrls" alt=""></div>
    </div>
    <indexBottom />
    <div>
      <!--            这是弹出层模态框-->
      <loginDialog :dialog-visible="dialogVisible" @dialogClose="dialogClose" />
      <!--            这是弹出层模态框-->
      <loginDialog
        :dialog-visible="dialogVisible"
        :dialogpass-visible="dialogpassVisible"
        @dialogPassClose="dialogPassClose"
        @dialogClose="dialogClose"
      />
    </div>
  </div>
</template>

<script>
import IndexHeader from '@/components/IndexHeader'
import { getDetailInfo } from '../../api/welcome'
import indexBottom from '../../components/indexBottom'
import loginDialog from '@/views/unLogin/components/loginDialog'
import { getConfigKey } from '@/api/login'
export default {
  name: 'Dynamicpages',
  components: {
    IndexHeader, indexBottom, loginDialog
  },
  data() {
    return {
      pageId: '',
      type: 0,
      detailContent: {},
      dialogVisible: false,
      dialogpassVisible: false,
      // 是否需要申请
      isNeedExamine: false
    }
  },
  //  监听
  watch: {
    dialogVisible(res) {
      console.log(res)
      this.$store.commit('changeDialogVisible', res)
      console.log(this.$store.state.topNav.dialogVisible)
    }
  },
  created() {
    this.getNeedExamine()
    this.pageId = this.$route.query.id
    // type（0公司动态，1课程宣传）
    this.type = this.$route.query.type
    this.onLoad()
  },
  methods: {
    /* 是否需要申请*/
    getNeedExamine() {
      getConfigKey().then(res => {
        this.isNeedExamine = res.msg === 'true'
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
      this.dialogVisible = true
    },
    onLoad() {
      this.getDynamicpages()
    },
    toLogin() {
      this.dialogVisible = true
    },
    dialogPassClose(data) {
      this.dialogpassVisible = data
    },
    dialogClose() {
      this.dialogVisible = false
    },
    getDynamicpages() {
      const obj = {
        id: this.pageId,
        type: this.type
      }
      getDetailInfo(obj).then(res => {
        console.log(res)
        if (res.code === 200) {
          if (res.data.createTime == null) {
            res.data.createTime = '暂无发布日期'
          } else {
            res.data.createTime = res.data.createTime.substring(0, 10)
          }
          this.detailContent = res.data
        }
      })
    },
    // 申请弹框
    applyFor() {
      this.dialogpassVisible = true
    },
    // 关闭详情
    close() {
      this.$router.push('/index')
    }
  }
}
</script>

<style scoped lang="less">
.Bell {
  float: left;
  margin: 23px 0 24px 208px;
  width: 14px;
  height: 18px;
  line-height: 25px;
  color:#4C97FD;
  //border-right: 1px solid #E7EDF6;
  //padding-right: 21px;
}
.tab {
  margin-left: 122px;

  .select{
    width: 100px;
    height: 30px;
    color: rgba(67, 146, 253, 1) !important;
    font-weight: bold;
  }

}
.stick {
  float: left;
  margin-top: 27px;
  //width: 0px;
  height: 16px;
  border-right: 1px solid #E7EDF6;
  //margin-left: 21px;
}
//  登录
.HeaderLogin {
  float: left;
  overflow: hidden;
  margin-top: 24px;
  margin-left: 21px;
  font-family: MicrosoftYaHei;
  .Login {
    float: left;
    color: #333333;
  }

  .Resign {
    float: right;
    font-size: 15px;
  }
}

.Box {
  position: relative;
  width: 1200px;
  margin: 70px auto 0;
  min-height: 100vh;
  background: rgba(255, 255, 255, 1);
  //background-color: black;
  //  ×
  .Cha {
    position: absolute;
    left: 30px;
    top: 30px;
    //width: 76px;
    height: 90px;
    cursor: pointer;
    .el-icon-arrow-left {
      width: 16px;
      height: 16px;
    }
    span {
      margin-left: 10px;
    }
  }

  //标题
  .Title {
    padding-top: 80px;
    text-align: center;
    color: rgba(48, 48, 48, 1);
    font-size: 24px;
  }

  //时间
  .Time {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
  }

  //内容
  .Content {
    width: 1040px;
    height: 800px;
    color: rgba(48, 48, 48, 1);
    font-size: 16px;
    line-height: 26px;
    margin: 40px auto 0;
    text-align: left;
    overflow: auto;
  }

  //格子
  .grid {
    width: 1040px;
    height: 400px;
    background: rgba(196, 196, 196, 1);
    margin: 30px auto 0;
  }
  .htmlContent{
    padding-bottom: 30px;
    /deep/ img{
      width: 1040px!important;
    }
  }
  ::v-deep.el-dialog {
    height: auto !important;

    .el-dialog__body {
      .content {
        min-height: 350px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
